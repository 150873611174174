<script lang="ts" setup>
import { searchResults, searchTerm } from '@/services/search'
import { computed } from 'vue'
import { useStore } from '@nanostores/vue'

interface Image {
  id: string
  height: Number
  width: Number
}

interface Props {
  alt?: string
  link?: string
  image_desktop: Image
  image_mobile: Image
  loading?: 'lazy' | 'eager'
  hideOnSearch?: boolean
}

const props = defineProps<Props>()
const search = useStore(searchTerm)
const results = useStore(searchResults)

const target = new RegExp('mara.com.br|^#').test(props.link ?? '') ? '_self' : '_blank'
const isSearch = computed(() => {
  return props.hideOnSearch && !!search.value && !!results.value
})
</script>

<template>
  <component :is="link ? 'a' : 'div'" :href="link" :target="target" v-if="!isSearch">
    <picture>
      <source
        :srcset="`https://img.imageboss.me/mara/cdn/format:auto,animation:true/assets/${image_desktop?.id}`"
        media="(min-width: 640px)"
        :height="image_desktop?.height.toString()"
        :width="image_desktop?.width.toString()"
      />

      <source
        :srcset="`https://img.imageboss.me/mara/cdn/format:auto,animation:true/assets/${image_mobile?.id}`"
        :height="image_mobile?.height.toString()"
        :width="image_mobile?.width.toString()"
      />

      <img
        :src="`https://img.imageboss.me/mara/cdn/format:auto,animation:true/assets/${image_mobile?.id}`"
        :alt="alt"
        class="w-3xl max-w-full mx-auto aspect-auto m-auto"
        :height="image_mobile?.height.toString()"
        :width="image_mobile?.width.toString()"
        :loading="loading || 'lazy'"
      />
    </picture>
  </component>
</template>
